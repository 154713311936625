import React from "react";
import NewsInsights from '../shared/NewsInsights/NewsInsights';
import "./WhyHenrico.scss";
import PageTitle from '../shared/PageTitle/PageTitle';
import './QualityOfLife.scss';
import QualityOfLifeForm from './QualityOfLifeForm';

// import './../hero/hero.scss';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import WhyHenricoHero from './WhyHenricoHero';

const pageTitle = 'Live in Henrico';

const QualityOfLife = () => {
    return (
        <div>
            <WhyHenricoHero />
            <Container>
            <div className="category-button-wrapper">
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/pro-business" onClick={(event) => this.updateCategory(event, 'Pro-Business')}  className="button-primary button-henrico-category" >
                            PRO-BUSINESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/market-access" onClick={(event) => this.updateCategory(event, 'Market Access')} className="button-primary button-henrico-category" >
                             MARKET ACCESS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/skilled-workforce" onClick={(event) => this.updateCategory(event, 'Skilled Workforce')} className="button-primary button-henrico-category" >
                            WORKFORCE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/competitive-operating-costs" onClick={(event) => this.updateCategory(event, 'Competitive Operating Costs')} className="button-primary button-henrico-category" >
                            OPERATING COSTS
                        </a>
                    </Col>
                </Row>
                <Row className="button-row button-row-1">
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/infrastructure"  onClick={(event) => this.updateCategory(event, 'Infrastructure')} className="button-primary button-henrico-category" >
                            INFRASTRUCTURE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/qualityoflife" className="button-primary button-henrico-category" >
                            QUALITY OF LIFE
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/fast-facts" onClick={(event) => this.updateCategory(event, 'Fast Facts')} className="button-primary button-henrico-category" >
                            FAST FACTS
                        </a>
                    </Col>
                    <Col lg={3} className="button-column">
                      <a href="/why-henrico/swam-certification" onClick={(event) => this.updateCategory(event, 'SWaM Certification')} className="button-primary button-henrico-category" >
                            SWAM
                        </a>
                    </Col>                     
                </Row>
            </div>
            </Container>
            <Container>
                <div class="pagetTitleContainer">
                    <PageTitle title={pageTitle} />  
                </div>
                <Row>
                    <Col lg={12} sm={12} md={12} xl={12}>
                    <p>There are more than 330,000 residents who are choosing to live in Henrico to live their best, and that number is expected to <a href="https://richmondmagazine.com/news/news/richmond-region-population-projections-2050/" target="_blank" rel="noopener noreferrer">surge by 26.4%</a> in the next 30 years. Top talent is falling in love with Henrico because word has spread that the cost of living is low (2.5% below the national average!) and the quality of life is high. In fact, the neighborhoods of Innsbrook and Short Pump were recognized as two of the <a href="https://stacker.com/stories/3808/best-places-live-america" target="_blank" rel="noopener noreferrer">Top 50 Best Places to Live in America</a>.<br/><br/>
                    Choose from a large pool of prospective recruits who are enjoying every moment. They’re excited about our commitment to creating thriving mixed-use communities, dedication to inclusion, and our focus on placemaking. Put simply, Henrico is a premier location to attract and retain the fulfilled workforce needed to advance your success. Because wherever you live, everywhere you look, there is always something fun to do in Henrico.<br></br><br></br>See what makes Henrico so special.</p>
                    <div className="featured-video" ><iframe width="860" height="484" src="https://player.vimeo.com/video/792623884?h=2558784de8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingRight: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/110503_HenricoEDA_118.png" alt="Exterior shot of historic Cedar Works condominiums in Rockett's Landing" width="100%"/>
                    <br/><br/>
                    <p><h3>Best Places to Live in Henrico</h3></p>
                    <p>Relax in the wide open green spaces of Varina. Soak up the charm of Lakeside as you pop into quirky shops and vintage boutiques. Move to the hustle and bustle of Short Pump. There is a neighborhood for every person and every stage of life – with more in store. Henrico is reimagining and repurposing several areas to embrace a mixed-use lifestyle where walkability and community are key. Plus, with the more than 34,000 Black, Hispanic, and multicultural residents who moved to Henrico from 2010-2020, everyone can feel right at home. New and revived spaces include:
                        <br/><br/>
                        <ul>
                            <li><a href="https://www.greencityva.com/" target="_blank" rel="noopener noreferrer">GreenCity</a>, a visionary ecodistrict set to break ground in 2023 and feature a 17,000-seat arena, Class A commercial offices, plus residential and retail space – all while maintaining a focus on sustainability </li>
                            <li><a href="https://www.shopregencymall.com/" target="_blank" rel="noopener noreferrer">Regency Square</a>, a former mall being transformed into a vibrant community complete with new housing, a <a href="https://swimswam.com/nova-of-virginia-turns-former-macys-into-13-million-aquatic-center/" target="_blank" rel="noopener noreferrer">$13 million aquatic center</a>, and more</li>
                            <li>Virginia Center Commons, which is making way for new apartments and the state-of-the-art <a href="https://www.wric.com/news/local-news/henrico-county/first-look-at-50-million-henrico-sports-and-events-center/" target="_blank" rel="noopener noreferrer">Henrico Sports & Events Center</a></li>
                        </ul> 
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingLeft: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/iStock-1139435499.png" alt="Elementary students smiling and writing at desk" width="100%"/>
                    <br/><br/>
                    <p><h3>Henrico Schools</h3></p>
                    <p>Henrico has a reputation for educational excellence and offers one of the highest concentrations of top-ranked public schools in our state. From elementary school and beyond, whether our diverse students are ready for the workforce right after high school or eager for higher education, we offer everything to prepare them for a lifetime of success.
                        <br/><br/>
                        <ul>
                            <li>15 Blue Ribbon Schools</li>
                            <li>Nationally recognized <a href="https://henricocte.com/" target="_blank" rel="noopener noreferrer">Career and Technical Education</a> program</li>
                            <li>Nearly 30 nearby colleges and universities</li>
                            <li>2 nearby HCBUs</li>
                            <li>An expanded <a href="https://www.nbc12.com/2022/01/12/henrico-schools-expand-adult-education-center-regency/" target="_blank" rel="noopener noreferrer">Adult Education Center</a></li>
                        </ul>  
                    </p>
                    </Col>                    
                </Row>                
                <Row>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingRight: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/iStock-1370067875.png" alt="Aerial view of road interchanges at sunrise" width="100%"/>
                    <br/><br/>
                    <p><h3>Henrico Transportation</h3></p>
                    <p>Work-life balance is even easier in Henrico, home of the 22-minute average commute. Thanks to quick access to interstates I-95 and I-64, your talent will spend less time in traffic and more time enjoying family and friends. You’ll love that they come to work in a great state of mind. They’ll appreciate easily reaching their after-hours destination. But it’s not just about the drive. From road to rail to air, we offer the transportation systems necessary to ensure a smooth ride.
                        <br/><br/>
                        <ul>
                            <li><a href="http://ridegrtc.com/brt" target="_blank" rel="noopener noreferrer">GRTC Pulse</a>, our region’s award-winning rapid transit system</li>
                            <li><a href="http://flyrichmond.com/" target="_blank" rel="noopener noreferrer">Richmond International Airport</a>, named the Best Big Little Airport in the U.S.</li>
                            <li>Amtrak, providing <a href="https://www.amtrak.com/alternative-to-richmond-washington-dc-buses-train" target="_blank" rel="noopener noreferrer">hourly morning service between Washington D.C. and Richmond</a> </li>
                        </ul>  
                    </p>
                    </Col>
                    <Col lg={6} sm={6} md={6} xl={6} style={{ paddingLeft: "40px" }}>
                    <img src="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/James_River_Raft.jpg.png" alt="Urban rafters taking a break to sit underneath a bridge and look out over the James River" width="100%"/>
                    <br/><br/>
                    <p><h3>Things to Do in Henrico</h3></p>
                    <p>What can’t you do when you live in Henrico? With our unbeatable location in Central Virginia nestled around Richmond, our residents enjoy attractions in Henrico and <a href="https://www.visitrichmondva.com/" target="_blank" rel="noopener noreferrer">what our entire region has to offer</a>. In Henrico, you can: 
                        <br/><br/>    
                        <ul>
                            <li>Join in the fun along the James River, offering the only urban class IV rapids in the country</li>
                            <li>Take a spin on the multi-use paths of the scenic <a href="https://www.virginiacapitaltrail.org/" target="_blank" rel="noopener noreferrer">Virginia Capital</a> and <a href="https://www.falllineva.org/" target="_blank" rel="noopener noreferrer">Fall Line</a> trails</li>
                            <li>Enjoy the exhilaration of NASCAR at <a href="https://www.richmondraceway.com/" target="_blank" rel="noopener noreferrer">Richmond Raceway</a></li>
                            <li>Unwind at <a href="https://www.lewisginter.org/" target="_blank" rel="noopener noreferrer">Lewis Ginter Botanical Garden</a>, voted one of the best botanical gardens in the country</li>
                            <li>Savor our nationally recognized <a href="https://gardenandgun.com/feature/souths-best-food-towns-richmond-the-sleeper/" target="_blank" rel="noopener noreferrer">food</a> and <a href="https://www.visitrichmondva.com/drink/richmond-beer-trail/" target="_blank" rel="noopener noreferrer">craft beer</a> scenes</li>
                            <li>Check out <a href="https://henrico-eda-site-assets.s3.amazonaws.com/why-henrico/HEN23011_Libraries_Infographic_FINAL_v2_compressed.pdf" target="_blank" rel="noopener noreferrer">Henrico County Public Library</a>, which offers 10 locations, features <a href="https://henricolibrary.org/digital-media-lab" target="_blank" rel="noopener noreferrer">digital media labs</a>, and has earned 5-Star Library recognition</li>
                        </ul>     
                    </p>
                    </Col>                                       
                </Row>    
            </Container>
            <QualityOfLifeForm />
            <Container>
                <NewsInsights />
            </Container>
        </div>
    );
    };

export default QualityOfLife;
